<template>
    <div v-loading="loading" class="main-frame">
        <el-container>
            <el-header class="banner-head">
                <div class="head-info">
                    <el-row>
                        <el-col style="text-align: left" :span="12">
                            <el-page-header style="height:60px;line-height:60px" title="返回" @back="goBack" content="文章详情"></el-page-header>
                        </el-col>
                    </el-row>
                </div>
            </el-header>
            <el-main>
                <el-tabs type="card" :stretch=true :before-leave='leaveTabOnclick'>
                    <el-tab-pane label="基本信息" name="0">
                        <ContentInfoModule :content-id="content_id" :operate-type="operate_type" ref="ContentInfoModule"/>
                    </el-tab-pane>
                    <el-tab-pane label="文章详情" name="1">
                        <ContentRichTextModule :content-id="content_id" :operate-type="operate_type" ref="ContentRichTextModule"/>
                    </el-tab-pane>
                </el-tabs>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    import ContentRichTextModule from "./module/ContentRichTextModule";
    import ContentInfoModule from "./module/ContentInfoModule";
    export default {
        name: "ContentDetailView",
        components: {
            ContentInfoModule,
            ContentRichTextModule,
        },
        data() {
            return {
                loading: false,
                content_id: '',
                operate_type: 'edit',
            }
        },
        created() {
            this.content_id = this.$route.query.id;
            if (this.content_id === undefined || this.content_id.length === 0) {
                this.$message.error("没获取到ID");
            }
        },
        methods: {
            leaveTabOnclick(activeName, oldActiveName) {
                // eslint-disable-next-line no-console
                console.log(activeName, oldActiveName)
            },
            goBack() {
                // 返回上一级路由
                this.$router.go(-1)
                // 跳转回指定路由
                // this.$router.push('/product-detail-mount')
            },
        }
    }
</script>

<style scoped></style>