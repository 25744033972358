<template>
  <div>
    <el-form
      v-loading="loading"
      class="type-form"
      :model="content_model"
      :rules="rules"
      ref="mount_model"
      label-width="140px"
    >
      <CopyPaste
        :button_status="this.content_id !== undefined && this.content_id.length > 0"
        @copyInfo="copyInfo(content_model)"
        @pasteInfo="pasteInfo"
        ref="copyPaste"
      ></CopyPaste>
      <el-form-item label="类型:" prop="type_id">
        <el-select
          v-model="content_model.type_id"
          @change="typeSelectOnclick"
          placeholder="请选择"
          loading-text="加载中..."
        >
          <el-option v-for="item in type_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="分类:" prop="category_id">
        <el-select v-model="content_model.category_id" placeholder="请选择" loading-text="加载中...">
          <el-option
            v-for="item in category_list_map.get(content_model.type_id)
              ? category_list_map.get(content_model.type_id)
              : []"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题:" prop="title">
        <el-input v-model="content_model.title" clearable></el-input>
      </el-form-item>
      <el-form-item label="别名:">
        <el-input v-model="content_model.old_alias" clearable></el-input>
      </el-form-item>
      <el-form-item label="文章链接:">
        <el-input v-model="content_model.alias" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="子标题:">
        <el-input
          v-model="content_model.subtitle"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="摘要:">
        <el-input
          v-model="content_model.summary"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="seo内容:">
        <el-input
          v-model="content_model.seo_title"
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 8 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="关键字:">
        <el-input v-model="content_model.key_words" clearable></el-input>
      </el-form-item>
      <el-form-item label="PC封面:">
        <el-input v-model="content_model.pc_cover_image" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 100%; height: 100%; margin-top: 10px"
              fit="contain"
              :src="content_model.pc_cover_image + $common.K_QINIU_IMAGE_SMALL_END_200"
              :preview-src-list="[content_model.pc_cover_image]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl style="margin-left: 20px">
              <dt class="edit-row-dt">
                <el-button size="mini" type="warning" @click="getImageByWeb('pc_cover_image')">资源选择</el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="WAP封面:">
        <el-input v-model="content_model.wap_cover_image" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 100%; height: 100%; margin-top: 10px"
              fit="contain"
              :src="content_model.wap_cover_image + $common.K_QINIU_IMAGE_SMALL_END_200"
              :preview-src-list="[content_model.wap_cover_image]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl style="margin-left: 20px">
              <dt class="edit-row-dt">
                <el-button size="mini" type="warning" @click="getImageByWeb('wap_cover_image')">资源选择</el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="来源(第三方内容):">
        <el-input v-model="content_model.source" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否全屏:">
        <el-switch v-model="content_model.full_screen" active-text="是" inactive-text="否"></el-switch>
      </el-form-item>
      <el-form-item label="是否禁止缩放:">
        <el-switch v-model="content_model.disable_viewport" active-text="是" inactive-text="否"></el-switch>
      </el-form-item>
      <el-form-item label="是否允许webp:">
        <el-switch v-model="content_model.enable_webp" active-text="是" inactive-text="否"></el-switch>
      </el-form-item>
      <el-form-item label="作者:">
        <el-input v-model="content_model.author" clearable></el-input>
      </el-form-item>
      <el-form-item label="作者头像:">
        <el-input v-model="content_model.author_avatar" clearable></el-input>
        <el-row>
          <el-col :span="12">
            <el-image
              style="width: 100%; height: 100%; margin-top: 10px"
              fit="contain"
              :src="content_model.author_avatar + $common.K_QINIU_IMAGE_SMALL_END_200"
              :preview-src-list="[content_model.author_avatar]"
              lazy
            ></el-image>
          </el-col>
          <el-col :span="12">
            <dl style="margin-left: 20px">
              <dt class="edit-row-dt">
                <el-button size="mini" type="warning" @click="getImageByWeb('author_avatar')">资源选择</el-button>
              </dt>
            </dl>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input-number v-model="content_model.number" :min="0" label="排序"></el-input-number>
      </el-form-item>
      <el-form-item label="发布时间:">
        <el-date-picker
          v-model="content_model.published_on"
          type="datetime"
          placeholder="选择日期时间"
          default-time="12:00:00"
          align="center"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="状态:">
        <el-select v-model="content_model.status" placeholder="请选择" loading-text="加载中...">
          <el-option
            v-for="item in $common.StatusSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="查看次数:">
        <el-input v-model="content_model.viewed_count" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="height: 4px"></div>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 460px; height: 50px" type="danger" @click="onSubmit">保存</el-button>
      </el-form-item>
      <el-form-item>
        <div style="height: 40px"></div>
      </el-form-item>
    </el-form>
    <FileChooseDrawerModule ref="contentInfoFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../../components/CopyPaste.vue";

export default {
  name: "ContentInfoModule",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  props: {
    contentId: {
      type: String,
      default: ""
    },
    operateType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      content_operate_type: "",
      content_model_id: "",
      content_model: {
        id: "",
        type_id: "",
        category_id: "",
        category_name: "",
        alias: "",
        old_alias: "",
        title: "",
        subtitle: "",
        summary: "",
        seo_title: "",
        key_words: "",
        pc_cover_image: "",
        wap_cover_image: "",
        source: "",
        full_screen: false,
        disable_viewport: false,
        enable_webp: false,
        author: "SMOK",
        author_avatar: "",
        number: 0,
        published_on: "",
        status: 2,
        viewed_count: 0
      },
      rules: {
        type_id: [{ required: true, message: "请选择类型", trigger: "blur" }],
        category_id: [
          { required: true, message: "请选择分类", trigger: "blur" }
        ],
        title: [
          { required: true, message: "请输入标题", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      },
      type_list: [],
      category_list: [],
      category_list_map: {},
      operate_type: this.operateType,
      content_id: this.contentId
    };
  },
  created() {
    if (this.operate_type.length > 0) {
      this.content_operate_type = this.operate_type;

      this.content_model_id = this.content_id;
      this.category_list_map = new Map();
      this.getTypeTree();

      if (this.content_id.length > 0) {
        this.getContentModel();
      }
    } else {
      this.$message.error("参数错误");
    }
  },
  methods: {
    getTypeTree() {
      let _me = this;
      let key = "contentStructureList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.type_list = val;
        for (let i of _me.type_list) {
          _me.category_list_map.set(i.id, i.category_list);
        }
        return;
      }

      let params = {
        status: 1,
        page_size: -1,
        page_number: 1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/type/show/structure", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.type_list = res.data === undefined ? [] : res.data;
            for (let i of _me.type_list) {
              _me.category_list_map.set(i.id, i.category_list);
            }

            _me.$common.setGlobalStorage(key, _me.type_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    typeSelectOnclick() {
      let _me = this;
      _me.content_model.category_id = "";
    },
    getContentModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.content_model_id
      };
      _me.$common
        .httpPost("/api/content/admin/article/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.content_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    async onSubmit() {
      if (!(await this.submitValidate())) return;
      if (this.content_operate_type === "edit") {
        this.updateContent();
      } else if (this.content_operate_type === "new") {
        this.addNewContent();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["mount_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error"
        });
        return false;
      }
    },
    updateContent() {
      let _me = this;
      let params = {
        id: _me.content_model.id,
        type_id: _me.content_model.type_id,
        category_id: _me.content_model.category_id,
        old_alias: _me.content_model.old_alias,
        title: _me.content_model.title,
        subtitle: _me.content_model.subtitle,
        summary: _me.content_model.summary,
        seo_title: _me.content_model.seo_title,
        key_words: _me.content_model.key_words,
        pc_cover_image: _me.content_model.pc_cover_image,
        wap_cover_image: _me.content_model.wap_cover_image,
        source: _me.content_model.source,
        full_screen: _me.content_model.full_screen,
        disable_viewport: _me.content_model.disable_viewport,
        enable_webp: _me.content_model.enable_webp,
        author: _me.content_model.author,
        author_avatar: _me.content_model.author_avatar,
        number: _me.content_model.number,
        published_on: _me.$common.formatTime(_me.content_model.published_on, 0),
        status: _me.content_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/article/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.$router.go(-1);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    addNewContent() {
      let _me = this;
      let params = {
        type_id: _me.content_model.type_id,
        category_id: _me.content_model.category_id,
        old_alias: _me.content_model.old_alias,
        title: _me.content_model.title,
        subtitle: _me.content_model.subtitle,
        summary: _me.content_model.summary,
        seo_title: _me.content_model.seo_title,
        key_words: _me.content_model.key_words,
        pc_cover_image: _me.content_model.pc_cover_image,
        wap_cover_image: _me.content_model.wap_cover_image,
        source: _me.content_model.source,
        full_screen: _me.content_model.full_screen,
        disable_viewport: _me.content_model.disable_viewport,
        enable_webp: _me.content_model.enable_webp,
        author: _me.content_model.author,
        author_avatar: _me.content_model.author_avatar,
        number: _me.content_model.number,
        published_on: _me.$common.formatTime(_me.content_model.published_on, 0),
        status: _me.content_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/article/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.operate_type = "edit";
            _me.content_id = res.data.id;
            _me.content_model.id = res.data.id;
            _me.$message.success("内容保存成功");
            _me.$emit("updateFrame", _me.content_id);
            _me.$router.go(-1);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.contentInfoFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_cover_image":
              _me.content_model.pc_cover_image = chooseFileList[0].file_url;
              break;
            case "wap_cover_image":
              _me.content_model.wap_cover_image = chooseFileList[0].file_url;
              break;
            case "author_avatar":
              _me.content_model.author_avatar = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.content_model = model;
      this.content_model.id = this.content_id;
      // 赋值文章的类型和分类
      var type_str = model.alias.split("/")[1];
      var category_str = model.alias.split("/")[2];
      this.type_list.forEach(ele => {
        if (ele.alias == type_str) {
          this.content_model.type_id = ele.id;
          ele.category_list.forEach(item => {
            if (item.alias == category_str) {
              this.content_model.category_id = item.id;
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped></style>
