<template>
  <div v-loading="loading">
    <div class="content-frame">
      <div v-show="choose_tag === 0" style="padding-top: 40px">
        <div id="rich-text-edit" class="rich-text-edit"></div>
      </div>
      <div v-show="choose_tag === 1" style="padding-top: 40px">
        <textarea
          id="code-view"
          class="code-view"
          v-model="content_model.rich_text"
          @input="codeViewChange"
        ></textarea>
      </div>
    </div>
    <div class="data-container">
      <div>
        <div style="padding: 0px 20px 10px 20px">
          <el-button :type="choose_tag === 0 ? 'warning' : ''" @click="choose_tag = 0"
            >富文本</el-button
          >
          <el-button :type="choose_tag === 1 ? 'warning' : ''" @click="choose_tag = 1"
            >代码</el-button
          >
        </div>
        <el-form
          ref="form"
          style="margin: 10px"
          :model="content_model"
          label-width="60px"
        >
          <el-form-item label="状态:">
            <el-select
              v-model="content_model.status"
              placeholder="请选择"
              loading-text="加载中..."
            >
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="激活:">
            <el-switch v-model="content_model.active" active-text="是" inactive-text="否">
            </el-switch>
          </el-form-item>
          <el-form-item label="JS:">
            <el-input
              v-model="content_model.js"
              placeholder="请输入JS链接"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="CSS:">
            <el-input
              v-model="content_model.css"
              placeholder="请输入CSS链接"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="danger" style="width: 100%" @click="saveContentModel"
              >保存</el-button
            >
          </el-form-item>
          <CopyPaste
            :button_status="
              this.content_model.article_id !== undefined &&
              this.content_model.article_id.length > 0
            "
            @copyInfo="copyInfo(content_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </div>
      <div style="padding: 0px 20px 0px 20px">
        <h3>详情历史列表:</h3>
        <dl>
          <dt
            v-for="(item, index) in content_model_list"
            @click="switchHistoryContent(item)"
            :key="index"
          >
            <p
              :style="item.id === content_model.id ? 'color:#03AEF9;' : 'color:#333333;'"
            >
              {{
                index + 1 + ".最后更新于:" + $common.formatTimesToTime(item.updated_at)
              }}
            </p>
          </dt>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import wangEditor from "wangeditor";
import Hashids from "hashids";
import CopyPaste from "../../../../components/CopyPaste.vue";
import md5 from "js-md5";

export default {
  name: "ContentIRichTextModule",
  components: {
    CopyPaste,
  },
  props: {
    contentId: {
      type: String,
      default: "",
    },
    operateType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      editor: null,
      choose_tag: 0,
      content_model: {
        id: "",
        article_id: "",
        rich_text: "",
        css: "",
        js: "",
        rich_type: 1,
        active: true,
        status: 2,
      },
      content_model_list: [],
      rules: {
        title: [
          { required: true, message: "请输入详情标题", trigger: "blur" },
          { min: 1, message: "详情标题大于 1 个字符", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    if (this.contentId !== undefined && this.contentId.length > 0) {
      // 已经存在文章
      this.getArticleContentList();
    }
  },
  mounted() {
    let _me = this;

    const editor = new wangEditor("#rich-text-edit");
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      _me.content_model.rich_text = newHtml;
    };
    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500; // 修改为 500ms
    // 取消自动 focus(不自动焦点)
    editor.config.focus = false;
    // 自定义 Alert
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case "success":
          _me.$message.success(s);
          break;
        case "info":
          _me.$message.info(s);
          break;
        case "warning":
          _me.$message.warning(s);
          break;
        case "error":
          _me.$message.error(s);
          break;
        default:
          _me.$message.info(s);
          break;
      }
    };
    // 配置字体
    editor.config.fontNames = [
      // 对象形式 v4.6.16
      // 字符串形式
      // "黑体",
      // "仿宋",
      // "楷体",
      // "标楷体",
      // "华文仿宋",
      // "华文楷体",
      // "宋体",
      // "微软雅黑",
      // "Arial",
      // "Tahoma",
      // "Verdana",
      "Times New Roman",
      // "Courier New",
      // "Ubuntu",
    ];
    // 设置编辑区域高度为 500px
    // editor.config.height = 800
    // 配置菜单栏，设置不需要的菜单
    editor.config.excludeMenus = ["head", "emoticon"];
    // 配置上传图片类型
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    // 一次最多上传 1 个图片 - 2021年01月08日14:48:06
    editor.config.uploadImgMaxLength = 1;
    // 配置自定义实现上传图片
    editor.config.customUploadImg = function (resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      let hashids = new Hashids(_me.product_id);
      for (let i = 0; i < resultFiles.length; i++) {
        _me.loading = true;
        let file = resultFiles[i];
        let file_path = "www/files/v2/article/";
        let file_name_md5 = md5(new Date().getTime() + file.name);
        let file_name = hashids.encodeHex(file_name_md5);
        let file_type = file.type.substring(file.type.lastIndexOf("/") + 1);
        let key = file_path + file_name + "." + file_type; // 文件资源名
        _me.$common.qiniuUploadImage(
          file,
          key,
          function (res) {
            // console.log('已上传大小，单位为字节：' + res.total.loaded)
            // console.log('本次上传的总量控制信息，单位为字节：' + res.total.size)
            // eslint-disable-next-line no-console
            console.log("当前上传进度，范围：0～100：" + res.total.percent);
          },
          function (err, data) {
            // console.log(err, data)
            _me.loading = false;
            if (err == undefined) {
              // insertImgFn 是获取图片 url 后，插入到编辑器的方法
              // 上传图片，返回结果，将图片插入到编辑器中
              // eslint-disable-next-line
              insertImgFn(_me.$common.K_QINIU_DOMAIN + data.key);
            } else {
              _me.$message({
                message: err || "获取数据失败",
                type: "error",
              });
            }
          }
        );
      }
    };

    //配置格式刷
    // const { BtnMenu } = wangEditor;

    // class format extends BtnMenu {
    //   constructor(editor) {
    //     const $elem = wangEditor.$(
    //       `<div class="w-e-menu" data-title="格式刷">
    //     <i class="el-icon-s-open"></i>
    //   </div>`
    //     );
    //     super($elem, editor);
    //   }
    //   for_style(domE) {
    //     let arr = {};
    //     for (let i = 0; i < domE.length; i++) {
    //       arr[domE[i]] = domE[domE[i]];
    //     }
    //     return arr;
    //   }
    //   font_style(domE) {
    //     let arr = [];
    //     Array.from(domE.attributes).forEach((v) => {
    //       arr.push(`${v.name}='${v.value}'`);
    //     });
    //     return arr;
    //   }
    //   string_style(val) {
    //     let arr = "";
    //     for (const key in val) {
    //       if (val.hasOwnProperty(key)) {
    //         const element = val[key];
    //         arr += `${key}:${element};`;
    //       }
    //     }
    //     return arr;
    //   }
    //   format_style(style, text) {
    //     //设置模板
    //     let _html = "";
    //     for (let i = 0; i < style.length; i++) {
    //       if (i == 0) {
    //         if (style[0].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[0].style.length; index++) {
    //             arr += ` ${style[0].style[index]}`;
    //           }
    //           //   console.log(
    //           //     (_html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`)
    //           //   );
    //           _html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`;
    //         } else {
    //           _html = `<${style[0].html} style="${style[0].style}">${text}</${style[0].html}>`;
    //         }
    //       } else {
    //         if (style[i].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[i].style.length; index++) {
    //             arr += ` ${style[i].style[index]}`;
    //           }
    //           _html = `<${style[i].html}  ${arr}>${_html}</${style[i].html}>`;
    //         } else {
    //           _html = `<${style[i].html} style="${style[i].style}">${_html}</${style[i].html}>`;
    //         }
    //       }
    //     }
    //     return _html;
    //   }
    //   clickHandler(e, type) {
    //     //console.log(this.$elem);
    //     //console.log("格式刷被点击");
    //     //获取editor全局编辑对象
    //     const editor = this.editor;
    //     // 判断当前格式刷是否已经被激活
    //     // 如果是激活状态：关闭格式刷
    //     if (this.formatShow) {
    //       this.formatShow = false;
    //       editor._brush = false;
    //       // editor._dblBrush = false;
    //       this.$elem.removeClass("w-e-active");
    //       editor.$textContainerElem.removeClass("brush");
    //       //console.log("关闭格式刷");
    //       return;
    //     }
    //     // 如果当前状态是未激活
    //     // 将格式刷改成激活状态
    //     this.formatShow = true;
    //     editor._brush = true;
    //     // this.$elem.addClass('w-e-active')
    //     // 如果是双击格式刷触发连续使用格式刷
    //     // 记录双击格式刷状态
    //     // editor._dblBrush = type === "dblclick" ? true : false;
    //     this.$elem.addClass("w-e-active");
    //     editor.$textContainerElem.addClass("brush");
    //     //获取选中对象
    //     let containerEle = editor.selection.getSelectionContainerElem();
    //     editor._html = [];
    //     if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //       //console.log("有font");
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.font_style(containerEle.elems[0]),
    //       });
    //     } else {
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //       });
    //     }
    //     //向上整合style
    //     //如果是顶级p标签name将style放在wrap里面，否则直接整合进style
    //     while (!containerEle.equal(editor.$textElem)) {
    //       containerEle = containerEle.parent();
    //       //console.log("循环中", containerEle);
    //       if (
    //         containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         editor._html.push({
    //           html: containerEle.elems[0].tagName.toLowerCase(),
    //           style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //         });
    //       }
    //       if (
    //         !containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         //console.log("非顶点");
    //         if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //           //console.log("有font");
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.font_style(containerEle.elems[0]),
    //           });
    //         } else {
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //           });
    //         }
    //       }
    //     }
    //     // editor._html = [];
    //     // editor._html.push(containerEle.elems[0].tagName.toLowerCase());
    //     //console.log(editor);
    //     editor.$textElem.on("mouseup", (e) => {
    //       //  //console.log("监听可编辑区域", e);
    //       //  //console.log("当前选区", editor.saveSelection());
    //       editor.$textElem.off("mouseleave", editor.selection.saveRange());
    //       if (editor._brush) {
    //         let text;
    //         let style = "";
    //         let wrapStyle = "";
    //         //console.log("选区是否为空", editor.selection.isSelectionEmpty());

    //         if (editor.selection.isSelectionEmpty()) {
    //           // 如果没有选中任何内容
    //           // 折叠选区
    //           editor.selection.collapseRange();
    //         } else {
    //           let range = editor.selection.getRange();
    //           let containerElem = editor.selection.getSelectionContainerElem();
    //           // 如果只刷了一行
    //           if (!containerElem.equal(editor.$textElem)) {
    //             text = editor.selection.getSelectionText();
    //             let _html = this.format_style(editor._html, text);
    //             //渲染模板
    //             editor.cmd.do("insertHTML", _html);
    //           } else {
    //             let elements = [];
    //             let startElem = editor.selection.getSelectionStartElem();
    //             let startElemCon = startElem.parent().parent();
    //             let endElem = editor.selection.getSelectionEndElem();
    //             let endElemCon = endElem.parent().parent();

    //             elements.push({
    //               type: "start",
    //               elem: startElem,
    //               offset: range.startOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: startElemCon,
    //             });

    //             while (!startElemCon.next().equal(endElemCon)) {
    //               startElemCon = startElemCon.next();
    //               elements.push({
    //                 type: "mid",
    //                 elem: startElemCon,
    //                 container: startElemCon,
    //               });
    //             }

    //             elements.push({
    //               type: "end",
    //               elem: endElem,
    //               offset: range.endOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: endElemCon,
    //             });

    //             elements.forEach((element) => {
    //               let container = element.container;
    //               let range = editor.selection.createRangeByElem(container, null, true);

    //               if (element.type === "start") {
    //                 range.setStart(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "mid") {
    //                 text = range.toString();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "end") {
    //                 range.setEnd(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               }
    //             });
    //           }
    //         }
    //       }
    //     });
    //   }
    //   // 菜单激活状态
    //   tryChangeActive() {}
    //   //  菜单点击事件
    // }

    // const E_format = "format";

    // editor.menus.extend(E_format, format);
    // editor.config.menus.push(E_format);

    // 创建编辑器
    editor.create();
    // 赋值编辑器
    this.editor = editor;
  },
  beforeDestroy() {
    // 调用销毁 API 对当前编辑器实例进行销毁
    this.editor.destroy();
    this.editor = null;
  },
  methods: {
    getArticleContentList() {
      let _me = this;
      let params = {
        article_id: _me.contentId,
        rich_type: 1, // 暂时只支持PC详情编辑
        page_size: -1,
        page_number: 1,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/article/text/find/list", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.content_model_list = res.data.array === undefined ? [] : res.data.array;
            if (_me.content_model_list.length > 0) {
              _me.content_model = _me.content_model_list[0];
              _me.editor.txt.html(_me.content_model.rich_text);
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    codeViewChange() {
      let _me = this;
      // console.log("输入事件")
      _me.editor.txt.html(_me.content_model.rich_text);
    },
    switchHistoryContent(model) {
      let _me = this;
      _me.content_model = model;
      _me.editor.txt.html(_me.content_model.rich_text);
    },
    saveContentModel() {
      let _me = this;
      let params = {
        article_id: _me.contentId,
        rich_text: _me.content_model.rich_text,
        css: _me.content_model.css,
        js: _me.content_model.js,
        rich_type: 1,
        active: true,
        status: _me.content_model.status,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/article/text/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("保存成功");
            _me.getArticleContentList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.content_model = model;
      if (model.rich_text != undefined && model.rich_text.length > 0) {
        this.editor.txt.html(model.rich_text);
      }
    },
  },
};
</script>

<style scoped>
.content-frame {
  width: 75%;
  height: 100%;
  text-align: left;
  /*background-color: #d8365f;*/
}

.data-container {
  width: 25%;
  height: 100%;
  float: right;
  text-align: left;
  /*background-color: #03AEF9;*/
}

.rich-text-edit {
  float: left;
  width: 100%;
  /* height: 100%; */
}

.rich-text-edit >>> .w-e-toolbar {
  z-index: 2000 !important;
}

.rich-text-edit >>> .w-e-text-container {
  z-index: 1999 !important;
  height: 550px !important;
}

.code-view {
  float: left;
  resize: none;
  /*为边框留的空6px*/
  margin: 0;
  padding: 0;
  width: calc(100% - 6px);
  min-height: 600px;
}
</style>
