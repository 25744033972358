<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header class="back" title="返回" @back="goBack" content="文章类型详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveTypeModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="type-form"
          :model="type_model"
          :rules="rules"
          ref="type_model"
          label-width="100px"
        >
          <el-form-item label="名称:" prop="name">
            <el-input v-model="type_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="别名:" prop="alias">
            <el-input v-model="type_model.alias" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态:">
            <el-select v-model="type_model.status" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in $common.StatusSelectList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序:">
            <el-input-number v-model="type_model.number" :min="0" label="排序"></el-input-number>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC端图标:">
            <el-input v-model="type_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="type_model.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[type_model.pc_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP端图标:">
            <el-input v-model="type_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="type_model.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[type_model.wap_image_url]"
                  lazy
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="this.type_id !== undefined && this.type_id.length > 0"
            @copyInfo="copyInfo(type_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="contentTypeFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../components/CopyPaste.vue";

export default {
  name: "ContentTypeDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      loading: false,
      type_id: "",
      type_model: {
        id: "",
        name: "",
        alias: "",
        number: 0,
        pc_image_url: "",
        wap_image_url: "",
        status: 2
      },
      deep_copy_type_model: {},
      rules: {
        name: [
          { required: true, message: "请输入类型名称", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ],
        alias: [
          { required: true, message: "请输入类型别名", trigger: "blur" },
          { min: 1, message: "长度大于 1 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.type_id = this.$route.query.id;
    if (this.type_id !== undefined && this.type_id.length > 0) {
      this.getProductTypeModel();
    }
  },
  methods: {
    getProductTypeModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.type_id
      };
      _me.$common
        .httpPost("/api/content/admin/type/find/id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.type_model = res.data;
            this.deep_copy_type_model = JSON.parse(
              JSON.stringify(this.type_model)
            );
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    async saveTypeModel() {
      if (!(await this.submitValidate())) return;
      if (this.type_id !== undefined && this.type_id.length > 0) {
        this.updateTypeModel();
      } else {
        this.newTypeModel();
      }
    },
    async submitValidate() {
      try {
        await this.$refs["type_model"].validate();
        // 验证成功
        return true;
      } catch (err) {
        this.$message({
          message: "请检查表单",
          type: "error"
        });
        return false;
      }
    },
    updateTypeModel() {
      let _me = this;
      let params = {
        id: _me.type_model.id,
        name: _me.type_model.name,
        alias: _me.type_model.alias,
        number: _me.type_model.number,
        pc_image_url: _me.type_model.pc_image_url,
        wap_image_url: _me.type_model.wap_image_url,
        status: _me.type_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/type/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            let origin_model_data = this.type_model;
            let copy_model_data = this.deep_copy_type_model;
            for (let k1 in copy_model_data) {
              for (let k2 in origin_model_data) {
                if (
                  k1 === k2 &&
                  copy_model_data[k1] !== origin_model_data[k2]
                ) {
                  _me.removeLocalStorage();
                  break;
                }
              }
            }
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newTypeModel() {
      let _me = this;
      let params = {
        name: _me.type_model.name,
        alias: _me.type_model.alias,
        number: _me.type_model.number,
        pc_image_url: _me.type_model.pc_image_url,
        wap_image_url: _me.type_model.wap_image_url,
        status: _me.type_model.status
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/content/admin/type/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.removeLocalStorage();
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    removeLocalStorage() {
      this.$common.removeGlobalStorage("contentTypeList");
      this.$common.removeGlobalStorage("contentStructureList");
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.contentTypeFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_image_url":
              _me.type_model.pc_image_url = chooseFileList[0].file_url;
              break;
            case "wap_image_url":
              _me.type_model.wap_image_url = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.type_model = model;
      this.type_model.id = this.type_id;
    }
  }
};
</script>

<style scoped></style>
